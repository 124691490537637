import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';

import CreatePostButtonMobile from '../create-post-button-mobile';
import { isSeo } from '../../../common/selectors/view-mode-selectors';
import styles from './post-list.scss';

const renderItem = props => {
  /* eslint-disable react/prop-types */
  const { post, query, type, onLikeClick, ItemComponent, itemConfig, index } = props;

  const wrapperClass = classNames(styles.listItemMobile, 'post-list__post-list-item');

  return (
    <div className={wrapperClass} key={post._id} id={post._id}>
      <ItemComponent
        index={index}
        type={type}
        post={post}
        query={query}
        onLikeClick={onLikeClick}
        itemConfig={itemConfig}
      />
    </div>
  );
};

const PostListMobile = props => {
  const { isSeo, posts, showCreatePostAction } = props;
  const postsList = posts.map((post, index) => renderItem({ ...props, post, index }));

  if (isSeo) {
    return <div data-hook="post-list">{postsList}</div>;
  }

  return (
    <div data-hook="post-list">
      {showCreatePostAction && (
        <div key="create-post">
          <CreatePostButtonMobile />
        </div>
      )}
      {postsList}
    </div>
  );
};

PostListMobile.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  type: PropTypes.string.isRequired,
  showCreatePostAction: PropTypes.bool,
  ItemComponent: PropTypes.func.isRequired,
  itemConfig: PropTypes.object,
  isSeo: PropTypes.bool,
};

const mapRuntimeToProps = state => ({ isSeo: isSeo(state) });

export default flowRight(connect(mapRuntimeToProps))(PostListMobile);
